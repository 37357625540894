jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    spinInput();
});

const menuMapping = new MappingListener({
    selector: ".menu-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-bottom-wrap",
    desktopMethod: "appendTo",
    breakpoint: 1024.98,
}).watch();

var productThumbnailSlider = new Swiper(
    ".product-thumbnail-slider .swiper-container",
    {
        slidesPerView: 4,
        spaceBetween: 20,
        observeParents: true,
        observer: true,
        breakpoints: {
            576: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
        },
    }
);

var productImageSlider = new Swiper(
    ".product-images-slider .swiper-container",
    {
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        observeParents: true,
        observer: true,
        thumbs: {
            swiper: productThumbnailSlider,
        },
        navigation: {
            prevEl: ".product-thumbnail-slider .swiper-prev",
            nextEl: ".product-thumbnail-slider .swiper-next",
        },
    }
);

function spinInput() {
    $(".spin-btn").click(function () {
        var $button = $(this);
        var type = $(this).data("spin");
        var oldValue = $button.parent().find("input").val();

        if (type == "inc") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
        //   AjaxCart.updatecart();
    });
}

var bannerHomeSlider = new Swiper(".home-banner .swiper-container", {
    pagination: {
        el: ".home-banner .swiper-pagination",
        clickable: true,
    },
});

var productSlider = new Swiper(".product-slider .swiper-container", {
    spaceBetween: 30,
    slidesPerView: 4,
    navigation: {
        prevEl: ".product-slider .swiper-prev",
        nextEl: ".product-slider .swiper-next",
    },
});
